import React from "react";

function App() {
  return (
    <div>
      <h1>Snake Game 1v1</h1>
      {/* Aquí iría el código de tu juego */}
    </div>
  );
}

export default App;
